import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Card, CardMedia, CardContent } from '@mui/material';

const GameStats = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch the data from the API when component mounts
        fetch('https://api.combienlejeu.fr/homepageStats')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching game stats:', error));
    }, []);

    const renderGameCard = (game, type) => (
        <Grid item xs={4}>
            <Card>
                <CardMedia
                    component="img"
                    alt={game.name}
                    height="140"
                    image={game.cover_url || 'path/to/default-image.jpg'} // Provide a default image if no cover_url
                    title={game.name}
                />
                <CardContent>
                    <Typography variant="h6">{game.name}</Typography>
                    <Typography variant="body2">
                        {type === 'topSellingGames' ? `Nombre de ventes: ${game.sales_count}` : `Différence de prix (en moyenne): ${game.average_price_difference}`}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );

    if (!data) return <Typography>Loading...</Typography>;

    return (
        <Paper>
            <Typography variant="h5">Games with Price Decrease</Typography>
            <Grid container spacing={3}>
                {data.gamesWithPriceDecrease.map(game => renderGameCard(game, 'gamesWithPriceDecrease'))}
            </Grid>

            <Typography variant="h5">Games with Price Increase</Typography>
            <Grid container spacing={3}>
                {data.gamesWithPriceIncrease.map(game => renderGameCard(game, 'gamesWithPriceIncrease'))}
            </Grid>

            <Typography variant="h5">Top Selling Games</Typography>
            <Grid container spacing={3}>
                {data.topSellingGames.map(game => renderGameCard(game, 'topSellingGames'))}
            </Grid>
        </Paper>
    );
};

export default GameStats;