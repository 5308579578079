import './App.css';
import {NavBar} from './component/unit_component/NavBar';
import {SearchGame} from "./component/unit_component/SearchGame";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import GameStats from './component/unit_component/GameStats';

function App() {
    return (
        <div className="App">
            <NavBar />
            <SearchGame />
            <GameStats />
        </div>
    );
}

export default App;