import axios from 'axios';
import {useEffect, useState} from 'react';
import {Autocomplete, Box, TextField} from "@mui/material";
import {debounce, trim} from 'lodash';

export const SearchGame = ({onGameData}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const triggerSearchInput = debounce((search) => {
        if (trim(search.target.value) !== '') {
            setLoading(true);
            axios.get(`https://api.combienlejeu.fr/search?name=${search.target.value}`)
                .then((response) => {
                    // Flatten the received data
                    const flattenedOptions = response.data.flatMap(consoleData => consoleData.games);
                    setOptions(flattenedOptions);
                    setLoading(false);
                }).catch((error) => {
                console.error(error);
            });
        } else {
            setOptions([]);
        }
    }, 300);

    return (
        <div className={"mt-6 w-1/2 mx-auto"}>
            <Autocomplete
                id="game-select"
                sx={{width: 850}}
                options={options}
                noOptionsText={"Aucun jeu trouvé"}
                filterOptions={(x) => x}
                autoHighlight
                loading={loading}
                groupBy={(option) => option.console}
                getOptionLabel={(option) => option.id}
                onInputChange={(event, newValue) => {
                    if (!newValue) setOptions([]); // Clear the options when input is empty
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            width="50"
                            srcSet={option.cover}
                            src={option.cover}
                            alt={option.name}
                        />
                        {option.console} - {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Chercher un jeu"
                        onChange={triggerSearchInput}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                    />
                )}
            />
        </div>
    );
}
